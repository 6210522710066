<template>
    <v-container>
        <v-card flat>
            <v-data-table
                :headers="headers"
                :loading="loading"
                :items="measurements"
                style="cursor:pointer"
                hide-default-footer
            >
            <template v-slot:[`item.weight_kg`]="{ item }">
                {{ JSON.parse(item.measuredDataJson).weight_kg  }}
            </template>
            <template v-slot:[`item.weight_lbs`]="{ item }">
                {{ JSON.parse(item.measuredDataJson).weight_lbs  }}
            </template>
            <template v-slot:[`item.Temperature_C`]="{ item }" >
                {{ JSON.parse(item.measuredDataJson).Temperature_C  }}
            </template>
            <template v-slot:[`item.Temperature_F`]="{ item }" >
                {{ JSON.parse(item.measuredDataJson).Temperature_F  }}
            </template>
            <template v-slot:[`item.BPM`]="{ item }" >
                {{ JSON.parse(item.measuredDataJson).BPM  }}
            </template>
            <template v-slot:[`item.Spo2`]="{ item }" >
                {{ JSON.parse(item.measuredDataJson).Spo2  }}
            </template>
            <template v-slot:[`item.PI`]="{ item }" >
                {{ JSON.parse(item.measuredDataJson).PI  }}
            </template>
            <template v-slot:[`item.Sys`]="{ item }" >
                {{ JSON.parse(item.measuredDataJson).Sys  }}
            </template>
            <template v-slot:[`item.Dias`]="{ item }" >
                {{ JSON.parse(item.measuredDataJson).Dias  }}
            </template>
            <template v-slot:[`item.Pul`]="{ item }" >
                {{ JSON.parse(item.measuredDataJson).Pul  }}
            </template>
            <template v-slot:[`item.glucose_mgL`]="{ item }" >
                {{ JSON.parse(item.measuredDataJson).glucose_mgL  }}
            </template>
            <template v-slot:[`item.glucose_mmolL`]="{ item }" >
                {{ JSON.parse(item.measuredDataJson).glucose_mmolL  }}
            </template>
            <template v-slot:[`item.cholesterol_mgL`]="{ item }" >
                {{ JSON.parse(item.measuredDataJson).cholesterol_mgL  }}
            </template>
            <template v-slot:[`item.cholesterol_mmolL`]="{ item }" >
                {{ JSON.parse(item.measuredDataJson).cholesterol_mmolL  }}
            </template>
            <template v-slot:[`item.measuredAt`]="{ item }" >
                {{ item.measuredAt.split('T')[0]  }}
            </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            loading: true,
            headerClass: 'black_inner--text !important',
            measurements: [],
            deviceType: -1
        }
    },
    computed: {
        headers() {
            var headers = []
            switch(this.deviceType) {
                case 1: headers = [{text: "Device Name", value: 'deviceIdentifier', class: this.headerClass},{text:"Measured At", value: 'measuredAt', class: this.headerClass},{text:"Temperature in Celcius", value: 'Temperature_C', class: this.headerClass},{text:"Temperature in Fahrenheit", value: 'Temperature_F', class: this.headerClass},{text:""}]       
                    break
                case 2: headers = [{text: "Device Name", value: 'deviceIdentifier', class: this.headerClass},{text:"Measured At", value: 'measuredAt', class: this.headerClass},{text:"BPM", value: 'BPM', class: this.headerClass},{text:"SpO2 (%)", value: 'Spo2', class: this.headerClass},{text:"PI", value: 'PI', class: this.headerClass},{text:""}]       
                    break
                case 3: headers = [{text: "Device Name", value: 'deviceIdentifier', class: this.headerClass},{text:"Measured At", value: 'measuredAt', class: this.headerClass},{text:"Systolic", value: 'Sys', class: this.headerClass},{text:"Diastolic", value: 'Dias', class: this.headerClass},{text:"Pulse", value: 'Pul', class: this.headerClass},{text:""}]       
                    break
                case 5: headers = [{text: "Device Name", value: 'deviceIdentifier', class: this.headerClass},{text:"Measured At", value: 'measuredAt', class: this.headerClass},{text:"Weight (kg)", value: 'weight_kg', class: this.headerClass},{text:"Weight (lbs))", value: 'weight_lbs', class: this.headerClass},{text:""}]      
                    break
                case 6: headers = [{text: "Device Name", value: 'deviceIdentifier', class: this.headerClass},{text:"Measured At", value: 'measuredAt', class: this.headerClass},{text:"Glucose (mg/dL)", value: 'glucose_mgL', class: this.headerClass},{text:"Glucose (mmol/L)", value: 'glucose_mmolL', class: this.headerClass},{text:""}]
                    break
                case 7: headers = [{text: "Device Name", value: 'deviceIdentifier', class: this.headerClass},{text:"Measured At", value: 'measuredAt', class: this.headerClass},{text:"Glucose (mg/dL)", value: 'glucose_mgL', class: this.headerClass},{text:"Glucose (mmol/L)", value: 'glucose_mmolL', class: this.headerClass},{text:"Cholesterol (mg/dL)", value: 'cholesterol_mgL', class: this.headerClass},{text:"Cholesterol (mmol/L)", value: 'cholesterol_mmolL', class: this.headerClass},{text:""}]
                    break
            }
            
            return headers
        },
    },
    async mounted(){
        await this.getDeviceById()
    },
    methods: {
        async getDeviceById () {
            await this.$store.dispatch('medicaldevice/getDeviceById', this.$route.params.id).then((res) => {
                this.measurements = res.measurementsData
                this.deviceType = res.deviceType
                this.loading = false
            })
        },
    }
}
</script>