var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.measurements,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.weight_kg",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).weight_kg)+" ")]}},{key:"item.weight_lbs",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).weight_lbs)+" ")]}},{key:"item.Temperature_C",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).Temperature_C)+" ")]}},{key:"item.Temperature_F",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).Temperature_F)+" ")]}},{key:"item.BPM",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).BPM)+" ")]}},{key:"item.Spo2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).Spo2)+" ")]}},{key:"item.PI",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).PI)+" ")]}},{key:"item.Sys",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).Sys)+" ")]}},{key:"item.Dias",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).Dias)+" ")]}},{key:"item.Pul",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).Pul)+" ")]}},{key:"item.glucose_mgL",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).glucose_mgL)+" ")]}},{key:"item.glucose_mmolL",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).glucose_mmolL)+" ")]}},{key:"item.cholesterol_mgL",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).cholesterol_mgL)+" ")]}},{key:"item.cholesterol_mmolL",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(JSON.parse(item.measuredDataJson).cholesterol_mmolL)+" ")]}},{key:"item.measuredAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.measuredAt.split('T')[0])+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }